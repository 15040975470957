import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetRegistrationInfoQuery,
  useSetPasswordMutation,
} from "../../../../services/endpoints/user";

import "./CompleteRegistration.scss";

const CompleteRegistration = () => {
  const errorCode = useSelector((state) => state.status.errorCode);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const { data } = useGetRegistrationInfoQuery(id);
  console.log("🚀 ~ CompleteRegistration ~ data:", data)
  const [setPassword] = useSetPasswordMutation();

  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Contraseña es requerida")
        .min(8, "La contraseña debe tener al menos 8 caracteres")
        .max(16, "La contraseña debe tener máximo 16 caracteres")
        .oneOf([Yup.ref("repeatPassword")], "Las contraseñas no coinciden"),
      repeatPassword: Yup.string()
        .required("Contraseña es requerida")
        .min(8, "La contraseña debe tener al menos 8 caracteres")
        .max(16, "La contraseña debe tener máximo 16 caracteres")
        .oneOf([Yup.ref("password")], "Las contraseñas no coinciden"),
    }),
    onSubmit: (formData) => {
      dispatch(setPassword(`${id}`, `${formData.password}`));
    },
  });

  return (
    <div>
      {!errorCode && (
        <Form onSubmit={formik.handleSubmit} className="login">
          <Form.Input
            type="password"
            placeholder="SuperSecretP455word"
            name="password"
            label="Introduzca su contraseña"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            className="login__input"
          />
          <Form.Input
            type="password"
            placeholder="SuperSecretP455word"
            name="repeatPassword"
            label="Repita su contraseña"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.repeatPassword}
            error={
              formik.touched.repeatPassword && formik.errors.repeatPassword
            }
            className="login__input"
          />
          <div>
            <Button
              type="submit"
              onClick={formik.handleSubmit}
              id="primary-button"
            >
              Enviar
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default CompleteRegistration;
