import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fact: "",
  facturacion: "",
};

export const facturacionSlice = createSlice({
  name: "facturacion",
  initialState,
  reducers: {
    setFacturacion: (state, action) => {
      state.facturacion = action.payload;
    },
    setFact: (state, action) => {
      state.fact = action.payload;
    },
  },
});

// Action creators
export const { setFact, setFacturacion } = facturacionSlice.actions;

export default facturacionSlice.reducer;
