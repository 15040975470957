import { apiSlice } from "../apiSlice";

const userEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserPermissions: build.query({
      query: (id) => ({
        url: `/getUserPermissions/${id}`,
      }),
    }),
    getPermissionsPaths: build.query({
      query: (id) => ({
        url: `/obtenerTodosPaths/${id}`,
      }),
    }),
    getRegistrationInfo: build.query({
      query: (id) => ({
        url: `/users/getPreregistered/${id}`,
      }),
    }),
    preregisterUser: build.mutation({
      query: (data) => ({
        url: "/users/preregisterUser",
        method: "POST",
        body: data,
      }),
    }),
    setPassword: build.mutation({
      query: (link, password) => ({
        url: `/users/setPassword`,
        method: "POST",
        body: { link, password },
      }),
    }),
  }),
});

export const {
  useGetPermissionsPathsQuery,
  useGetUserPermissionsQuery,
  useGetRegistrationInfoQuery,
  usePreregisterUserMutation,
  useSetPasswordMutation,
} = userEndpoints;
