import { NavLink } from "react-router-dom";

import "./Cards.scss";

const Cards = ({ id, lista, header }) => {
  const filteredList = lista.filter((item) => item.status === id);

  return (
    <div className="cards vertical-cards">
      <div className="header">{header}</div>
      {filteredList.map((item) => {
        return (
          <NavLink
            to={`/administracion/detalles?id=${item.id}`}
            key={item.id}
            style={{ textDecoration: "none", color: "inherit" }}
            activeStyle={{ textDecoration: "none", color: "inherit" }}
          >
            <div id={item.id} className="card">
              <div>
                {item.nombre} {item.apellido_paterno}
                {item.apellido_materno}
              </div>
              <div>
                Asesor: {item.nombre_asesor} {item.apellido_paterno_asesor}
              </div>
              <div></div>
            </div>
          </NavLink>
          //     }
          //   />
        );
      })}
    </div>
  );
};
export default Cards;
