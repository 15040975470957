import { apiSlice } from "../apiSlice";

export const PpsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.query({
      query: () => `/pp/list`,
      method: "GET",
      providesTags: ["pp"],
    }),
    getDetails: builder.query({
      query: (id) => {
        // transform id to int
        id = parseInt(id);
        return {
          url: `/pp/${id}`,
          method: "GET",
        };
      },
    }),
    create: builder.mutation({
      query: (body) => ({
        url: `/pp/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["pp"],
    }),
    update: builder.mutation({
      query: (body) => ({
        url: `/pp/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["pp"],
    }),
    createOrUpdate: builder.mutation({
      query: (body) => ({
        url: `/pp/createOrUpdate`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["pp"],
    }),
  }),
});

export const {
  useGetListQuery,
  useGetDetailsQuery,
  useCreateMutation,
  useUpdateMutation,
  useCreateOrUpdateMutation,
} = PpsApi;
