import authReducer from "./slices/authSlice";
import studiesReducer from "./slices/studiesSlice";
import statusReducer from "./slices/statusSlice";
import profileReducer from "./slices/profileSlice";
import facturacionReducer from "./slices/facturacionSlice";

const rootReducer = {
  auth: authReducer,
  status: statusReducer,
  studies: studiesReducer,
  profile: profileReducer,
  facturacion: facturacionReducer,
};

export default rootReducer;
