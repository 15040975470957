import Router from "./router/Router";
// import { Outlet } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { Navbar } from "./features/Shared/components/Navbar/Navbar";
// import Home from "./features/Shared/components/Home/Home";

import LogRocket from 'logrocket';


import "./App.scss";

LogRocket.init('dh0kfb/pp-02nop');
// LogRocket.identify(JSON.parse(localStorage.getItem("user_id")), {
// 	name: JSON.parse(localStorage.getItem("name")),
// 	appName: 'PP',
// 	browserName: browserName,
// 	browserVersion: browserVersion,
// 	osName: osName,
// 	osVersion: osVersion,
// 	deviceType: deviceType,
// 	deviceDetect: deviceDetect
// });

function App() {

	return (
		<Container fluid className="App">
			<Navbar />
			<Router />
		</Container>
	);
}

export default App;
