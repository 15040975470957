import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";

import "./Validacion.scss";
import { selectCurrentUser } from "../../../store/slices/authSlice";

const Validacion = () => {
  const user = useSelector(selectCurrentUser);

  return (
    <Container>
      <div className="header">Estudios - Validación</div>
      <div className="captura">
        <ListaEstudios status="2" user_id={user.id} />
      </div>
    </Container>
  );
};

export default Validacion;
