import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";
import { selectCurrentUser } from "../../../store/slices/authSlice";

import "./Captura.scss";

const Captura = () => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);

  const handleNuevoEstudio = () => {
    navigate("/estudios/captura/nuevo");
  };
  return (
    <Container>
      <div className="header">Estudios - En captura</div>
      <div className="captura">
        <div id="buttons">
          <Button className="button" onClick={() => handleNuevoEstudio()}>
            Nuevo
          </Button>
        </div>

        <ListaEstudios status="1" user_id={user.id} />
      </div>
    </Container>
  );
};

export default Captura;
