import { apiSlice } from "../apiSlice";

export const ProfileApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: (id) => `/users/getUser/${id}`,
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: `/users/updatePerfil`,
        method: "POST",
        body,
      }),
    }),
    uploadProfileFile: builder.mutation({
      query: (body) => {
        const formData = new FormData();
        formData.append("image", body.image);
        formData.append("id", body.id);
        formData.append("tipoImagen", body.tipoImagen);
        return {
          url: `/users/uploadProfileFile`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUploadProfileFileMutation,
} = ProfileApi;
