import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";

import Login from "../features/Auth/components/Login/Login";

import Dashboard from "../features/Configuracion/Dashboard";
import Perfil from "../features/Configuracion/Perfil/Perfil";
import Facturacion from "../features/Configuracion/Facturacion/Facturacion";
import VerImagenes from "../features/Shared/components/VerImagenes/VerImagenes";
import { selectCurrentToken } from "../store/slices/authSlice";

export const RouterConfiguracion = () => {
  const token = useSelector(selectCurrentToken);
  return (
    <Routes>
      {token ? (
        <Route element={<ProtectedRoute />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/perfil" element={<Perfil />} />
          <Route exact path="/facturacion" element={<Facturacion />} />
          <Route exact path="/verImagenes" element={<VerImagenes />} />
        </Route>
      ) : (
        <>
          <Route index element={<Login />} />
          <Route path="*" element={<Login />} />
        </>
      )}
    </Routes>
  );
};
