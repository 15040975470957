import { useSelector } from "react-redux";
import DetallesPerfil from "./DetallesPerfil";
import { useState } from "react";
import EditarPerfil from "./EditarPerfil";
import { useGetProfileQuery } from "../../../services/endpoints/profile";
import { selectCurrentUser } from "../../../store/slices/authSlice";

import "./Perfil.scss";

const Perfil = () => {
  const [edit, setEdit] = useState(false);
  const user = useSelector(selectCurrentUser);

  const { data: profile = "" } = useGetProfileQuery(user.id, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="container">
      <div className="perfil-table-container">
        <div className="subheader">Perfil</div>

        {edit ? (
          <EditarPerfil profile={profile} setEdit={setEdit} />
        ) : (
          <DetallesPerfil setEdit={setEdit} />
        )}
      </div>
    </div>
  );
};

export default Perfil;
