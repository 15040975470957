import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";
import { selectCurrentUser } from "../../../store/slices/authSlice";

import "./Enproceso.scss";

const Enproceso = () => {
  const user = useSelector(selectCurrentUser);

  return (
    <Container>
      <div className="header">Estudios - En proceso</div>
      <div className="captura">
        <ListaEstudios status="3" user_id={user.id} />
      </div>
    </Container>
  );
};

export default Enproceso;
