import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Container, Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  regimenesFiscales,
  tiposPersonas,
  usosCfdi,
  tiposLogos,
} from "../../../constants/constants";
import { useUpdateProfileMutation } from "../../../services/endpoints/profile";
import { setError, setMessage } from "../../../store/slices/statusSlice";
import { selectCurrentUser } from "../../../store/slices/authSlice";

import "./Perfil.scss";

const EditarPerfil = ({ profile, setEdit }) => {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);

  const [updateProfile, { isSuccess, isError }] = useUpdateProfileMutation();

  const formik = useFormik({
    initialValues: {
      tipo_persona: profile.tipo_persona || "Persona Física",
      rfc: profile.rfc || "",
      razon_social: profile.razon_social || "",
      codigo_postal: profile.codigo_postal || "",
      regimen_fiscal:
        profile.regimen_fiscal || "601 - General de Ley Personas Morales",
      uso_cfdi: profile.uso_cfdi || "G01 - Adquisición de mercancías",
      tipo_logo: profile.tipo_logo || "logoATiempo",
    },
    validationSchema: Yup.object({
      tipo_persona: Yup.string().required("Tipo de persona es requerido"),
      rfc: Yup.string().required("RFC es requerido"),
      razon_social: Yup.string().required("Razón social es requerido"),
      codigo_postal: Yup.string().required("Código postal es requerido"),
      regimen_fiscal: Yup.string().required("Régimen fiscal es requerido"),
      uso_cfdi: Yup.string().required("Uso de CFDI es requerido"),
    }),
    onSubmit: (formData) => {
      formData.id = user.id;
      updateProfile(formData);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage("Operación exitosa"));
      setEdit(false);
    }
    if (isError) {
      dispatch(setError("Hubo un error"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  return (
    <Container>
      <div className="subsubheader">Editar</div>
      <Form onSubmit={formik.handleSubmit} className="register">
        <label htmlFor="tipo_persona" className="labels">
          Tipo de persona
        </label>
        <select
          id="tipo_persona"
          name="tipo_persona"
          value={formik.values.tipo_persona}
          onChange={formik.handleChange}
          className="perfil-select"
        >
          {tiposPersonas.map((item) => {
            return (
              <option key={item.text} value={item.text}>
                {item.text}
              </option>
            );
          })}
        </select>
        <Form.Input
          type="text"
          placeholder="ABCD123456XYZ"
          name="rfc"
          label="RFC"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.rfc}
          error={formik.touched.rfc && formik.errors.rfc}
          className="register__input"
        />
        <Form.Input
          type="text"
          placeholder="Nombre de la empresa"
          name="razon_social"
          label="Razón social"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.razon_social}
          error={formik.touched.razon_social && formik.errors.razon_social}
          className="register__input"
        />
        <Form.Input
          type="text"
          placeholder="Código postal"
          name="codigo_postal"
          label="Código postal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.codigo_postal}
          error={formik.touched.codigo_postal && formik.errors.codigo_postal}
          className="register__input"
        />
        <label htmlFor="tipo_persona" className="labels">
          Régimen fiscal
        </label>
        <select
          id="regimen_fiscal"
          name="regimen_fiscal"
          value={formik.values.regimen_fiscal}
          onChange={formik.handleChange}
          className="perfil-select"
        >
          {regimenesFiscales.map((item) => {
            return (
              <option
                key={item.text}
                value={item.text}
                defaultValue={
                  item.text === formik.values.regimen_fiscal ? true : false
                }
              >
                {item.text}
              </option>
            );
          })}
        </select>
        <label htmlFor="uso_cfdi" className="labels">
          Uso de CFDI
        </label>
        <select
          id="uso_cfdi"
          name="uso_cfdi"
          value={formik.values.uso_cfdi}
          onChange={formik.handleChange}
          className="perfil-select"
        >
          {usosCfdi.map((item) => {
            return (
              <option
                key={item.value}
                value={item.value}
                defaultValue={
                  item.value === formik.values.uso_cfdi ? true : false
                }
              >
                {item.text}
              </option>
            );
          })}
        </select>
        <label htmlFor="uso_cfdi" className="labels">
          Tipo de Logo
        </label>
        <select
          id="tipo_logo"
          name="tipo_logo"
          value={formik.values.tipo_logo}
          onChange={formik.handleChange}
          className="perfil-select"
        >
          {tiposLogos.map((item) => {
            return (
              <option
                key={item.value}
                value={item.value}
                defaultValue={
                  item.value === formik.values.tipo_logo ? true : false
                }
              >
                {item.text}
              </option>
            );
          })}
        </select>
      </Form>

      <div id="botonera">
        <Button
          type="button"
          id="secondary-button"
          onClick={() => setEdit(false)}
        >
          Regresar
        </Button>
        <Button type="submit" id="primary-button" onClick={formik.handleSubmit}>
          Guardar
        </Button>
      </div>
    </Container>
  );
};

export default EditarPerfil;
