import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectCurrentToken } from "../store/slices/authSlice";

export const ProtectedRoute = ({ children, redirectTo = "/home" }) => {
  const token = useSelector(selectCurrentToken);

  if (!token) {
    return <Navigate to={redirectTo} />;
  }
  return children ? children : <Outlet />;
};
