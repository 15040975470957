import { NavLink } from "react-router-dom";

import "./Cards.scss";

const Cards = ({ id, lista, header }) => {
  const filteredList = lista?.res?.filter((item) => item.status === id) || [];
  console.log("filteredList:::2", filteredList);

  return (
    <div className="cards vertical-cards">
      <div className="header">{header}</div>
      {filteredList.map((item) => {
        return (
          <NavLink
            to={`/estudios/captura/detalles?id=${item.id}`}
            key={item.id}
            style={{ textDecoration: "none", color: "inherit" }}
            activeStyle={{ textDecoration: "none", color: "inherit" }}
          >
            <div id={item.id} className="card">
              {item.nombre} {item.apellido_paterno}
              {item.apellido_materno}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default Cards;
