import { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { useGetListQuery } from "../../services/endpoints/studies";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/slices/authSlice";
import Cards from "../Shared/components/Cards/Cards";

import "./Dashboard.scss";

const Dashboard = () => {
  const user = useSelector(selectCurrentUser);

  const args = {
    asesor: parseInt(user.id, 10),
  };
  const { data: lista = [], refetch } = useGetListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  console.log("lista", lista.res);

  useEffect(() => {
    refetch();
    window.scrollTo(0, 0);
  }, [refetch]);

  return (
    <Container>
      <div className="dashboard">
        <div className="title">Estudios</div>
        <div className="cards-container">
          <Cards id={1} lista={lista} header="Captura" />
          <Cards id={2} lista={lista} header="Validación" />
          <Cards id={3} lista={lista} header="Proceso" />
          <Cards id={4} lista={lista} header="Completados" />
          <Cards id={5} lista={lista} header="Reprocesos" />
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
