import { apiSlice } from "../apiSlice";

export const StudiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.query({
      query: ({ asesor, status }) => {
        const params = status ? { asesor, status } : { asesor };
        return {
          url: "/studies/getList",
          method: "GET",
          params,
        };
      },
      providesTags: ["Studies"],
    }),
    getAll: builder.query({
      query: (id) => {
        return {
          url: `/studies/getAll/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Studies"],
    }),
    getFullList: builder.query({
      query: () => {
        return {
          url: `/studies/getFullList/`,
          method: "GET",
        };
      },
      providesTags: ["Studies"],
    }),
    getDetails: builder.query({
      query: (id) => {
        return {
          url: `/studies/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Study"],
    }),
    createStudy: builder.mutation({
      query: (body) => ({
        url: `/studies/createStudy`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Studies"],
    }),
    updateStudy: builder.mutation({
      query: (body) => ({
        url: `/studies/update`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Studies"],
    }),
    changeStatus: builder.mutation({
      query: (body) => {
        return {
          url: `/studies/changeStatus`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Studies"],
    }),
    deleteStudy: builder.mutation({
      query: (id) => ({
        url: `/studies/deleteStudy/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Studies"],
    }),
    getStatuses: builder.query({
      query: () => `/studies/getStatuses`,
      method: "GET",
    }),
    uploadFile: builder.mutation({
      query: (body) => {
        const formData = new FormData();
        formData.append("image", body.image);
        formData.append("id", body.id);
        formData.append("tipoImagen", body.tipoImagen);
        return {
          url: `/studies/uploadFile`,
          method: "POST",
          body: formData,
        };
      },
    }),
    getFiles: builder.query({
      query: (id) => `/studies/getFiles/${id}`,
      method: "GET",
    }),
    getLFiles: builder.query({
      query: (id) => `/studies/getFiles/${id}`,
      method: "GET",
    }),
    getLogs: builder.query({
      query: (id) => {
        return {
          url: `/studies/getLogs/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Logs"],
    }),
    addLogs: builder.mutation({
      query: (body) => {
        return {
          url: `/studies/addLogs`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Logs"],
    }),
  }),
});

export const {
  useGetListQuery,
  useGetAllQuery,
  useGetFullListQuery,
  useGetDetailsQuery,
  useCreateStudyMutation,
  useUpdateStudyMutation,
  useChangeStatusMutation,
  useDeleteStudyMutation,
  useGetStatusesQuery,
  useUploadFileMutation,
  useGetFilesQuery,
  useLazyGetLFilesQuery,
  useGetLogsQuery,
  useAddLogsMutation,
} = StudiesApi;
