import { useState } from "react";
import { tableB, tableC } from "../../../../constants/constants.js";

const usePublicPp = () => {
  const findByF0 = (value) => {
    const result = tableB.reduce((prev, curr) => {
      return Math.abs(curr.f0 - value) < Math.abs(prev.f0 - value)
        ? curr
        : prev;
    });
    return result;
  };

  const findByBasicAmount = (value) => {
    const result = tableB.reduce((prev, curr) => {
      return Math.abs(curr.basic_amount - value) <
        Math.abs(prev.basic_amount - value)
        ? curr
        : prev;
    });
    return result;
  };

  const findByAge = (value) => {
    const result = tableC.reduce((prev, curr) => {
      return Math.abs(curr.age - value) < Math.abs(prev.age - value)
        ? curr
        : prev;
    });
    return result;
  };

  const MIN_SDI = 207;
  const MAX_SDI = 2590;
  const MIN_AGE = 60;
  const MAX_AGE = 65;
  const MIN_QUOTED_WEEKS = 500;
  const MAX_QUOTED_WEEKS = 2350;
  const MAX_DAILY_PENSION = 2590;
  const MIN_MONTHLY_PENSION = 7005;
  const MAX_MONTHLY_PENSION = 78842.5;

  const [sdi, setSdi] = useState(MIN_SDI);
  const [age, setAge] = useState(MIN_AGE);
  const [quotedWeeks, setQuotedWeeks] = useState(MIN_QUOTED_WEEKS);
  const [wife, setWife] = useState(0);
  const [sons, setSons] = useState(0);

  const monthlyIncome = sdi * 30.416;
  const factor = sdi / 62.33;

  const increaseFactor = (quotedWeeks - 500) / 52;
  const basicAmount = findByF0(factor).basic_amount * 1;
  const annualIncrease = findByBasicAmount(basicAmount).annual_increase * 1;
  const pensionPercentage = increaseFactor * annualIncrease + basicAmount * 100;
  const pensionBase = (pensionPercentage * sdi) / 100;
  const appliedPercentage = findByAge(age).percent;

  let pension = pensionBase * (appliedPercentage / 100);
  const elevenPercent = pension * 0.11;

  pension += elevenPercent;

  pension = pension > MAX_DAILY_PENSION ? MAX_DAILY_PENSION : pension;
  let monthlyPension = pension * 30.4166666666667;
  if (monthlyPension > MAX_MONTHLY_PENSION) {
    monthlyPension = MAX_MONTHLY_PENSION;
  }
  if (monthlyPension < MIN_MONTHLY_PENSION) {
    monthlyPension = MIN_MONTHLY_PENSION;
  }

  const accumulatedAt80 = monthlyPension * 12 * (80 - age);

  return {
    sdi,
    setSdi,
    age,
    setAge,
    quotedWeeks,
    setQuotedWeeks,
    wife,
    setWife,
    sons,
    setSons,
    MIN_SDI,
    MAX_SDI,
    MIN_AGE,
    MAX_AGE,
    MIN_QUOTED_WEEKS,
    MAX_QUOTED_WEEKS,
    MAX_DAILY_PENSION,
    MAX_MONTHLY_PENSION,
    monthlyIncome,
    factor,
    increaseFactor,
    basicAmount,
    annualIncrease,
    pensionPercentage,
    pensionBase,
    appliedPercentage,
    pension,
    elevenPercent,
    monthlyPension,
    accumulatedAt80,
  };
};

export default usePublicPp;
