import { useEffect, useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  baseurl,
  camposPPs,
  tiposImagenesDisplay,
  urlBaseAdministracion,
} from "../../../../constants/constants";
import { Button, Dropdown, Form, Table } from "semantic-ui-react";
import { useCreateOrUpdateMutation } from "../../../../services/endpoints/pps";
import { useGetDetailsQuery } from "../../../../services/endpoints/studies";
import { toast } from "react-toastify";
import AwesomeSlider from "react-awesome-slider";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-awesome-slider/dist/styles.css";
import jsPDF from "jspdf";

import "./EditaPP.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const convertImageToPdf = (imageSrc, scalePercent = 90) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // Determinar la orientación de la imagen
      const isLandscape = img.width > img.height;
      const orientation = isLandscape ? "l" : "p";

      // Crear PDF con la orientación adecuada
      const pdf = new jsPDF(orientation, "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Calcular dimensiones escaladas
      const scale = scalePercent / 100;
      let scaledWidth, scaledHeight, offsetX, offsetY;

      if (isLandscape) {
        // Si la imagen es horizontal
        scaledWidth = pageWidth * scale;
        scaledHeight = (img.height * scaledWidth) / img.width;
        offsetX = (pageWidth - scaledWidth) / 2;
        offsetY = (pageHeight - scaledHeight) / 2;
      } else {
        // Si la imagen es vertical
        scaledHeight = pageHeight * scale;
        scaledWidth = (img.width * scaledHeight) / img.height;
        offsetX = (pageWidth - scaledWidth) / 2;
        offsetY = (pageHeight - scaledHeight) / 2;
      }

      // Añadir la imagen al PDF
      pdf.addImage(img, "JPEG", offsetX, offsetY, scaledWidth, scaledHeight);

      const pdfBlob = pdf.output("blob");
      resolve(URL.createObjectURL(pdfBlob));
    };
    img.onerror = reject;
    img.src = imageSrc;
  });
};

const EditaPP = () => {
  const [searchParams] = useSearchParams();
  const estudios_id = searchParams.get("id");
  const navigate = useNavigate();
  const [createOrUpdate] = useCreateOrUpdateMutation();
  const [pdfs, setPdfs] = useState([]);

  const { data: pp_data = [] } = useGetDetailsQuery(estudios_id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const newImages = [];
    const newPdfs = [];

    tiposImagenesDisplay.forEach(({ key }) => {
      const filePath = pp_data.length && pp_data[0][key];
      if (filePath) {
        if (filePath.endsWith(".pdf") || filePath.endsWith(".blob")) {
          newPdfs.push(`${baseurl}/${filePath}`);
        } else if (filePath.endsWith(".jpg") || filePath.endsWith(".png")) {
          newImages.push(`${baseurl}/${filePath}`);
        }
      }
    });

    setPdfs(newPdfs);

    const imageToPdfPromises = newImages.map((imageSrc) =>
      convertImageToPdf(imageSrc)
    );

    Promise.all(imageToPdfPromises)
      .then((pdfUrls) => {
        setPdfs((prevPdfs) => [...prevPdfs, ...pdfUrls]);
      })
      .catch((error) => {
        console.error("Error al convertir imágenes a PDF", error);
      });
  }, [pp_data]);

  const [numPages, setNumPages] = useState(pdfs.map(() => null));
  const [currentPage, setCurrentPage] = useState({});
  const [zoomLevel, setZoomLevel] = useState({});

  const onDocumentLoadSuccess = useCallback(
    (file) =>
      ({ numPages: loadedNumPages }) => {
        setNumPages((prevNumPages) => ({
          ...prevNumPages,
          [file]: loadedNumPages,
        }));
        setCurrentPage((prevCurrentPage) => ({
          ...prevCurrentPage,
          [file]: 1,
        }));
      },
    []
  );

  const changePage = useCallback(
    (file, offset) => {
      setCurrentPage((prevCurrentPage) => {
        const newCurrentPage = { ...prevCurrentPage };
        const newPageNumber = Math.max(
          1,
          Math.min(newCurrentPage[file] + offset, numPages[file] || 1)
        );
        newCurrentPage[file] = newPageNumber;
        return newCurrentPage;
      });
    },
    [numPages]
  );

  const zoomIn = (file) => {
    setZoomLevel((prevZoomLevel) => ({
      ...prevZoomLevel,
      [file]: (prevZoomLevel[file] || 1) * 1.2,
    }));
  };

  const zoomOut = (file) => {
    setZoomLevel((prevZoomLevel) => ({
      ...prevZoomLevel,
      [file]: (prevZoomLevel[file] || 1) / 1.2,
    }));
  };
  const campos = camposPPs.reduce((acc, campo) => {
    const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    acc[campo.value] = timestampRegex.test(camposPPs[0]?.[campo.value])
      ? pp_data[0]?.[campo.value]?.split("T")[0] || ""
      : pp_data[0]?.[campo.value] || "";
    return acc;
  }, {});

  const yups = camposPPs.reduce((acc, campo) => {
    acc[campo.value] = campo.yup;
    return acc;
  }, {});

  const formik = useFormik({
    initialValues: campos,
    validationSchema: Yup.object(yups),
    onSubmit: (values) => {
      values.estudios_id = estudios_id;
      Object.keys(values).forEach((key) => {
        const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
        if (timestampRegex.test(values[key])) {
          values[key] = values[key].split("T")[0];
        }
      });
      createOrUpdate(values);
      setTimeout(() => {
        notifySaved();
        navigate(`${urlBaseAdministracion}/DetallePP?id=${estudios_id}`);
      }, 1000);
    },
  });

  const notifySaved = useCallback(() => {
    toast.success("Registro guardado correctamente");
  }, []);
  const handleCancelar = () => {
    navigate(`${urlBaseAdministracion}/DetallePP?id=${estudios_id}`);
  };

  return (
    <div className="container-edit-pp">
      <div className="form-container-edit-pp">
        <Form onSubmit={formik.handleSubmit} className="cargaDatosPP">
          <div className="register__subtitle">Captura de datos del PP</div>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Campo</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {camposPPs.map((campo) => {
                return campo.type === "dropdown" ? (
                  <>
                    <Table.Row key={campo.value}>
                      <Table.Cell key={`${campo.value}-left`}>
                        {campo.text}
                      </Table.Cell>
                      <Table.Cell key={`${campo.value}-right`}>
                        <Dropdown
                          id={campo.value}
                          key={campo.value}
                          name={campo.value}
                          label={campo.text}
                          fluid
                          selection
                          options={campo.options}
                          onChange={(_e, { value }) =>
                            formik.setFieldValue(campo.value, value)
                          }
                          value={formik.values[campo.value]}
                          error={
                            formik.touched[campo.value] &&
                            formik.errors[campo.value]
                          }
                          onFocus={formik.handleFocus}
                          onBlur={formik.handleBlur}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                ) : (
                  <>
                    <Table.Row key={campo.value}>
                      <Table.Cell>{campo.text}</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          type={campo.type}
                          id={campo.value}
                          key={campo.value}
                          name={campo.value}
                          // label={campo.text}
                          placeholder={campo.text}
                          onChange={formik.handleChange}
                          value={
                            campo.type === "date"
                              ? formik.values[campo.value]?.split("T")[0]
                              : formik.values[campo.value]
                          }
                          error={
                            formik.touched[campo.value] &&
                            formik.errors[campo.value]
                          }
                          onFocus={formik.handleFocus}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                );
              })}
            </Table.Body>
          </Table>

          <div id="botonera1">
            <Button
              type="button"
              onClick={() => handleCancelar()}
              id="secondary-button"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              onClick={formik.handleSubmit}
              id="primary-button"
            >
              Guardar
            </Button>
          </div>
        </Form>
      </div>
      <div className="preview-container-edit-pp">
        {pdfs.length > 0 ? (
          <AwesomeSlider>
            {pdfs.map((media) => (
              <div key={media} className="pdf-container">
                <Document
                  file={media}
                  onLoadSuccess={onDocumentLoadSuccess(media)}
                >
                  <Page
                    pageNumber={currentPage[media] || 1}
                    scale={zoomLevel[media] || 1}
                  />
                </Document>
                <div className="pdf-controls">
                  {/* Botones para cambiar de página */}
                  <Button onClick={() => changePage(media, -1)}>‹</Button>
                  {currentPage[media] || 1} de {numPages[media]}
                  <Button onClick={() => changePage(media, 1)}>›</Button>
                  {/* Botones para zoom in y zoom out */}
                  <Button onClick={() => zoomOut(media)}>alejar</Button>
                  <Button onClick={() => zoomIn(media)}>acercar</Button>
                </div>
              </div>
            ))}
          </AwesomeSlider>
        ) : (
          <div>No hay PDFs</div>
        )}
      </div>
    </div>
  );
};

export default EditaPP;
