import { useEffect, useState } from "react";
import {
  enviroment,
  version,
  waitTime,
  warningHeader,
} from "../../../../constants/constants.js";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Image } from "semantic-ui-react";
import {
  logOut,
  selectCurrentToken,
  selectCurrentUser,
} from "../../../../store/slices/authSlice.js";
import {
  setError,
  setErrorCode,
  setIsLoading,
  setMessage,
} from "../../../../store/slices/statusSlice.js";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../../../assets/Logo.png";

import "./Navbar.scss";
import "react-toastify/dist/ReactToastify.css";

export const Navbar = () => {
  const [showInfo, setshowInfo] = useState(false);
  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const error = useSelector((state) => state.status.error);
  const message = useSelector((state) => state.status.message);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setIsLoading(false));
    dispatch(setError(null));
    dispatch(logOut());
  };

  if (error) toast.warning(error);
  if (message) toast.success(message);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setError(null));
      dispatch(setErrorCode(null));
      dispatch(setMessage(null));
    }, 1000);
  }, [dispatch, error, message]);

  const info = `Env: ${enviroment} --- Version: ${version}`;

  const handleMouseEnter = (event) => {
    if (event.shiftKey) {
      event.target.style.cursor = "wait";
      setshowInfo(true);
    }
  };

  const handleMouseLeave = (event) => {
    event.target.style.cursor = "default";
    setshowInfo(false);
  };

  return (
    <div className="navbar-container">
      <Menu>
        {token ? (
          <>
            <Menu.Item>
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <NavLink to={"/"}>
                  <Image src={Logo} size="mini" />
                </NavLink>
              </div>
            </Menu.Item>
            {showInfo && (
              <Menu.Item>
                <span>{info}</span>
              </Menu.Item>
            )}

            {user.barType === "long" ? (
              <>
                <Menu.Item>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link active-link" : "link inactive-link"
                    }
                    to={"/administracion/dashboard"}
                  >
                    Administración
                  </NavLink>
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link active-link" : "link inactive-link"
                    }
                    to={"/configuracion/dashboard"}
                  >
                    Configuración
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "link active-link" : "link inactive-link"
                    }
                    to={"/estudios/dashboard"}
                  >
                    Estudios
                  </NavLink>
                </Menu.Item>
              </>
            )}

            <Menu.Item position="right" onClick={logout}>
              <span className="link">Salir</span>
            </Menu.Item>
            {warningHeader && (
              <Menu.Item>
                <i aria-hidden="true" className="warning icon"></i>
              </Menu.Item>
            )}
          </>
        ) : (
          <>
            <Menu.Item>
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <NavLink to={"/"}>
                  <Image src={Logo} size="mini" />
                </NavLink>
              </div>
            </Menu.Item>
            {showInfo && (
              <Menu.Item>
                <span>{info}</span>
              </Menu.Item>
            )}
            <Menu.Item position="right">
              <NavLink className="link" to={"/"}>
                Iniciar sesión
              </NavLink>
            </Menu.Item>
            {warningHeader && (
              <Menu.Item>
                <i aria-hidden="true" className="warning icon"></i>
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
      <ToastContainer
        position="top-right"
        autoClose={waitTime}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="colored" // TODO: Add theme
      />
    </div>
  );
};
