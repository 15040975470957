import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  errorCode: null,
  isLoading: false,
  message: null,
};

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

// Action creators
export const { setIsLoading, setError, setErrorCode, setMessage } =
  statusSlice.actions;

export default statusSlice.reducer;
