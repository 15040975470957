import { useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button, Container, Table, Dropdown } from "semantic-ui-react";

import "./EditaEstudio.scss";
import {
  enviroment,
  estadosRepublicaMexicana,
  tipos_estudio,
  urlBaseEstudios,
} from "../../../../constants/constants";
import {
  useGetDetailsQuery,
  useUpdateStudyMutation,
} from "../../../../services/endpoints/studies";
import { toast } from "react-toastify";

const DetalleEstudio = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  const { data: estudio = [] } = useGetDetailsQuery(id);
  const [updateStudy] = useUpdateStudyMutation();

  if (enviroment === "dev") console.log("estudio", estudio);

  const convertStringToArray = (texto) => {
    if (!texto) return [];
    texto = texto?.trim();
    var arreglo = texto?.split(",").map(function (elemento) {
      return elemento?.trim();
    });
    return arreglo;
  };

  const convertArrayToString = (arr) => {
    return arr?.join(", ");
  };

  const formik = useFormik({
    initialValues: {
      nombre: estudio[0]?.nombre,
      apellido_paterno: estudio[0]?.apellido_paterno,
      apellido_materno: estudio[0]?.apellido_materno,
      rfc: estudio[0]?.rfc,
      curp: estudio[0]?.curp,
      nss: estudio[0]?.nss,
      entidades_federativas: convertStringToArray(
        estudio[0]?.entidades_federativas
      ),
      tipo_estudio: estudio[0]?.tipo_estudio,
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Nombre es requerido"),
      apellido_paterno: Yup.string().required("Apellido paterno es requerido"),
      apellido_materno: Yup.string().required("Apellido materno es requerido"),
      rfc: Yup.string()
        .min(10, "El RFC debe ser mínimo 10 caracteres")
        .max(13, "El RFC debe ser máximo 13 caracteres"),
      curp: Yup.string().length(18, "El CURP debe ser de 18 caracteres"),
      nss: Yup.string().length(11, "El NSS debe ser de 11 caracteres"),
      entidades_federativas: Yup.array().min(
        1,
        "Debes seleccionar al menos una entidad federativa"
      ),
      tipo_estudio: Yup.string().required("Tipo de estudio es requerido"),
    }),
    onSubmit: (formData) => {
      formData.entidades_federativas = convertArrayToString(
        formik.values.entidades_federativas
      );
      formData.id = id;
      updateStudy(formData);
      notifySaved();
      navigate(`${urlBaseEstudios}/captura/detalles?id=${id}`);
    },
  });

  const handleCancelar = () => {
    navigate(`${urlBaseEstudios}/captura/detalles?id=${id}`);
  };

  const notifySaved = useCallback(() => {
    toast.success("Estudio actualizado");
  }, []);

  return (
    <Container fluid className="container">
      <div className="header">Editar</div>
      <div className="edita-estudio">
        <Form onSubmit={formik.handleSubmit} className="form">
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Campo</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Tipo de estudio</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Selecciona el tipo de estudio"
                    fluid
                    search
                    selection
                    options={tipos_estudio}
                    name="tipo_estudio"
                    onChange={(e, { value }) => {
                      formik.setFieldValue("tipo_estudio", value);
                    }}
                    value={formik.values.tipo_estudio}
                    error={
                      formik.touched.tipo_estudio && formik.errors.tipo_estudio
                    }
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Nombre</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="text"
                    placeholder="Juan"
                    name="nombre"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nombre}
                    error={formik.touched.nombre && formik.errors.nombre}
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Apellido Paterno</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="text"
                    placeholder="Pérez"
                    name="apellido_paterno"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.apellido_paterno}
                    error={
                      formik.touched.apellido_paterno &&
                      formik.errors.apellido_paterno
                    }
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Apellido Materno</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="text"
                    placeholder="López"
                    name="apellido_materno"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.apellido_materno}
                    error={
                      formik.touched.apellido_materno &&
                      formik.errors.apellido_materno
                    }
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>RFC</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="text"
                    placeholder="ABCD123456XYZ (10 - 13 caracteres)"
                    name="rfc"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rfc}
                    error={formik.touched.rfc && formik.errors.rfc}
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>CURP</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="text"
                    placeholder="ABCD123456HDFLRN00 (18 caracteres)"
                    name="curp"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.curp}
                    error={formik.touched.curp && formik.errors.curp}
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>NSS</Table.Cell>
                <Table.Cell>
                  <Form.Input
                    type="text"
                    placeholder="12345678901 (11 caracteres)"
                    name="nss"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nss}
                    error={formik.touched.nss && formik.errors.nss}
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Entidades federativas</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Selecciona las entidades federativas"
                    fluid
                    multiple
                    search
                    selection
                    options={estadosRepublicaMexicana}
                    name="entidades_federativas"
                    onChange={(e, { value }) => {
                      formik.setFieldValue("entidades_federativas", value);
                    }}
                    value={formik.values.entidades_federativas}
                    error={
                      formik.touched.entidades_federativas &&
                      formik.errors.entidades_federativas
                    }
                    className="input"
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div id="botonera1">
            <Button
              type="button"
              onClick={() => handleCancelar()}
              id="secondary-button"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              onClick={formik.handleSubmit}
              id="primary-button"
            >
              Guardar
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default DetalleEstudio;
