import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Container } from "semantic-ui-react";
import { baseurl, tiposImagenesDisplay } from "../../../../constants/constants";
import { useGetDetailsQuery } from "../../../../services/endpoints/studies";
import { useGetProfileQuery } from "../../../../services/endpoints/profile";
import { useGetFacturaQuery } from "../../../../services/endpoints/facturacion";

import "./VerImagenes.scss";

const VerImagenes = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [data, setData] = useState();
  const id = searchParams.get("id");
  const tipo = searchParams.get("tipo");

  const skipProfile = tipo === "perfil" ? false : true;
  const skipEstudio = tipo === "estudio" ? false : true;
  const skipFacturacion = tipo === "facturacion" ? false : true;
  const { data: estudioData = [] } = useGetDetailsQuery(id, {
    skipEstudio,
    refetchOnMountOrArgChange: true,
  });
  const { data: profileData = [] } = useGetProfileQuery(id, {
    skipProfile,
    refetchOnMountOrArgChange: true,
  });
  const { data: facturacionData = [] } = useGetFacturaQuery(id, {
    skipFacturacion,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (tipo === "estudio") {
      setData(estudioData[0]);
    } else if (tipo === "perfil") {
      setData(profileData);
    } else if (tipo === "facturacion") {
      setData(facturacionData);
    }
  }, [estudioData, facturacionData, profileData, tipo]);

  return (
    <Container fluid className="container">
      <div className="header">Ver imagenes</div>

      {tiposImagenesDisplay.map((imagen) => {
        return (
          <div key={imagen.key} className="images">
            {/** if image is pdf, download, else, show */}
            {data?.[imagen.key]?.includes(".pdf") ||
            data?.[imagen.key]?.includes(".blob") ? (
              <div>
                <a
                  href={`${baseurl}/${data?.[imagen.key]}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="action-button">
                    Descargar {imagen.text}
                  </Button>
                </a>
              </div>
            ) : (
              data?.[imagen.key] && (
                <div className="imagen">
                  <div className="text">{imagen.text}</div>
                  <img
                    src={`${baseurl}/${data?.[imagen.key]}`}
                    alt={imagen.text}
                  />
                </div>
              )
            )}
          </div>
        );
      })}

      <div className="return-button">
        <button onClick={() => navigate(-1)} className="ui button">
          Regresar
        </button>
      </div>
      <br />
    </Container>
  );
};

export default VerImagenes;
