import { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { useGetFullListQuery } from "../../services/endpoints/studies";
import Cards from "./Cards/Cards";
import "./Dashboard.scss";

const Dashboard = () => {
  const { data: lista = [], refetch } = useGetFullListQuery();

  console.log("lista", lista);

  useEffect(() => {
    refetch();
    window.scrollTo(0, 0);
  }, [refetch]);

  return (
    <Container fluid>
      <div className="dashboard">
        <div className="title">Administración</div>
        <div className="cards-container">
          <Cards id={1} lista={lista} header="Captura" />
          <Cards id={2} lista={lista} header="Validación" />
          <Cards id={3} lista={lista} header="Proceso" />
          <Cards id={4} lista={lista} header="Completados" />
          <Cards id={5} lista={lista} header="Reprocesos" />
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
