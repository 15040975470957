import * as Yup from "yup";

export const enviroment = process.env.REACT_APP_MODE;

export const version = process.env.REACT_APP_VERSION;

let warning = false;

export const baseurl = process.env.REACT_APP_API;

if (enviroment === "dev") {
  console.log("baseurl", baseurl);
  console.log("warning", warning);
  warning = true;
}

export const warningHeader = warning;

export const waitTime = 3000;

export const shortbrand = "A Tiempo Planea";

export const brand = "A Tiempo Planea";

export const leyend = "Planeando tu futuro";

export const longbrand = "Planeando tu futuro";

export const arrEstudios = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];
export const arrEstudiosName = "Estudios";

// Sections should be added here

// export const arrSga = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
// export const arrSgaName = "SGA";

export const arrMenu = [
  [arrEstudios, arrEstudiosName],
  // [arrSga, arrSgaName],
];

export const urlBaseEstudios = "/estudios";
export const urlBaseDashboard = `${urlBaseEstudios}/dashboard`;
export const urlBaseCaptura = `${urlBaseEstudios}/captura`;

export const urlBaseAdministracion = "/administracion";
export const urlBaseAdministracionDashboard = `${urlBaseAdministracion}/dashboard`;

export const tiposPersonas = [
  { text: "Persona Física" },
  { text: "Persona Física con Actividad Empresarial" },
  { text: "Persona Moral" },
];

export const regimenesFiscales = [
  { text: "601 - General de Ley Personas Morales" },
  { text: "603 - Personas Morales con Fines no Lucrativos" },
  { text: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { text: "606 - Arrendamiento" },
  { text: "607 - Régimen de Enajenación o Adquisición de Bienes" },
  { text: "608 - Demás ingresos" },
  { text: "609 - Consolidación" },
  { text: "611 - Ingresos por Dividendos (socios y accionistas)" },
  {
    text: "612 - Personas Físicas con Actividades Empresariales y Profesionales",
  },
  { text: "614 - Ingresos por intereses" },
  { text: "615 - Régimen de los ingresos por obtención de premios" },
  { text: "616 - Sin obligaciones fiscales" },
  {
    text: "620 - Sociedades Cooperativas de Producción que optan por Diferir sus Ingresos",
  },
  { text: "621 - Incorporación Fiscal" },
  { text: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
  { text: "623 - Opcional para Grupos de Sociedades" },
  { text: "624 - Coordinados" },
  { text: "626 - RESICO" },
  { text: "628 - Hidrocarburos" },
  {
    text: "629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  },
  { text: "630 - Enajenación de acciones en bolsa de valores" },
];

export const usosCfdi = [
  { text: "G01 - Adquisición de mercancías" },
  { text: "G02 - Devoluciones, descuentos o bonificaciones" },
  { text: "G03 - Gastos en general" },
  { text: "I01 - Construcciones" },
  { text: "I02 - Mobilario y equipo de oficina por inversiones" },
  { text: "I03 - Equipo de transporte" },
  { text: "I04 - Equipo de computo y accesorios" },
  { text: "I05 - Dados, troqueles, moldes, matrices y herramental" },
  { text: "I06 - Comunicaciones telefónicas" },
  { text: "I07 - Comunicaciones satelitales" },
  { text: "I08 - Otra maquinaria y equipo" },
  { text: "D01 - Honorarios médicos, dentales y gastos hospitalarios" },
  { text: "D02 - Gastos médicos por incapacidad o discapacidad" },
  { text: "D03 - Gastos funerales" },
  { text: "D04 - Donativos" },
  {
    text: "D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
  },
  { text: "D06 - Aportaciones voluntarias al SAR" },
  { text: "D07 - Primas por seguros de gastos médicos" },
  { text: "D08 - Gastos de transportación escolar obligatoria" },
  {
    text: "D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
  },
  { text: "D10 - Pagos por servicios educativos (colegiaturas)" },
  { text: "P01 - Por definir" },
];

export const promotorias = [
  { key: "Freti", value: "Freti", text: "Freti" },
  {
    key: "Estratega Lobo & Asociados",
    value: "Estratega Lobo & Asociados",
    text: "Estratega Lobo & Asociados",
  },
  { key: "Profundum", value: "Profundum", text: "Profundum" },
  { key: "LOMA", value: "LOMA", text: "LOMA" },
  { key: "Otra", value: "Otra", text: "Otra" },
  { key: "Ninguna", value: "Ninguna", text: "Ninguna" },
];

export const tipos_estudio = [
  { key: "Diagnóstico", value: "Diagnóstico", text: "Diagnóstico" },
  {
    key: "Proyectivo de Pensión",
    value: "Proyectivo de Pensión",
    text: "Proyectivo de Pensión",
  },
];
export const estadosRepublicaMexicana = [
  { key: "Aguascalientes", text: "Aguascalientes", value: "Aguascalientes" },
  { key: "Baja California", text: "Baja California", value: "Baja California" },
  {
    key: "Baja California Sur",
    text: "Baja California Sur",
    value: "Baja California Sur",
  },
  { key: "Campeche", text: "Campeche", value: "Campeche" },
  { key: "Chiapas", text: "Chiapas", value: "Chiapas" },
  { key: "Chihuahua", text: "Chihuahua", value: "Chihuahua" },
  {
    key: "Ciudad de México",
    text: "Ciudad de México",
    value: "Ciudad de México",
  },
  { key: "Coahuila", text: "Coahuila", value: "Coahuila" },
  { key: "Colima", text: "Colima", value: "Colima" },
  { key: "Durango", text: "Durango", value: "Durango" },
  {
    key: "Estado de México",
    text: "Estado de México",
    value: "Estado de México",
  },
  { key: "Guanajuato", text: "Guanajuato", value: "Guanajuato" },
  { key: "Guerrero", text: "Guerrero", value: "Guerrero" },
  { key: "Hidalgo", text: "Hidalgo", value: "Hidalgo" },
  { key: "Jalisco", text: "Jalisco", value: "Jalisco" },
  { key: "Michoacán", text: "Michoacán", value: "Michoacán" },
  { key: "Morelos", text: "Morelos", value: "Morelos" },
  { key: "Nayarit", text: "Nayarit", value: "Nayarit" },
  { key: "Nuevo León", text: "Nuevo León", value: "Nuevo León" },
  { key: "Oaxaca", text: "Oaxaca", value: "Oaxaca" },
  { key: "Puebla", text: "Puebla", value: "Puebla" },
  { key: "Querétaro", text: "Querétaro", value: "Querétaro" },
  { key: "Quintana Roo", text: "Quintana Roo", value: "Quintana Roo" },
  { key: "San Luis Potosí", text: "San Luis Potosí", value: "San Luis Potosí" },
  { key: "Sinaloa", text: "Sinaloa", value: "Sinaloa" },
  { key: "Sonora", text: "Sonora", value: "Sonora" },
  { key: "Tabasco", text: "Tabasco", value: "Tabasco" },
  { key: "Tamaulipas", text: "Tamaulipas", value: "Tamaulipas" },
  { key: "Tlaxcala", text: "Tlaxcala", value: "Tlaxcala" },
  { key: "Veracruz", text: "Veracruz", value: "Veracruz" },
  { key: "Yucatán", text: "Yucatán", value: "Yucatán" },
  { key: "Zacatecas", text: "Zacatecas", value: "Zacatecas" },
];

export const tiposImagenesCaptura = [
  {
    text: "RFC",
    value: "rfc_imagen",
  },
  {
    text: "CURP",
    value: "curp_imagen",
  },
  {
    text: "Comprobante de pago",
    value: "comprobantepago_imagen",
  },
  {
    text: "Estado de cuenta del Afore",
    value: "afore_imagen",
  },
  {
    text: "INE anverso",
    value: "ineanverso_imagen",
  },
  {
    text: "INE reverso",
    value: "inereverso_imagen",
  },
];

export const tiposImagenesDisplay = [
  { key: "afore_imagen", text: "Afore" },
  { key: "comprobantepago_imagen", text: "Comprobante de pago" },
  { key: "curp_imagen", text: "CURP" },
  { key: "ineanverso_imagen", text: "INE anverso" },
  { key: "inereverso_imagen", text: "INE reverso" },
  { key: "rfc_imagen", text: "RFC" },
  {
    key: "constancia_situacion_fiscal",
    text: "Constancia de situación fiscal",
  },
  { key: "logo", text: "Logotipo propio" },
];

export const tiposLogos = [
  { text: "Logo de ATiempo", value: "logoATiempo" },
  { text: "Logo Propio", value: "logoPropio" },
  { text: "Sin Logo", value: "sinLogo" },
];

export const textosCambioNivel = [
  { id: 0, text: "Enviar a Captura", toast: "Estudio enviado a Captura" },
  { id: 1, text: "Enviar a Validación", toast: "Estudio enviado a Validación" },
  { id: 2, text: "Enviar a En Proceso", toast: "Estudio enviado a En Proceso" },
  { id: 3, text: "Enviar a Completado", toast: "Estudio enviado a Completado" },
  { id: 4, text: "Enviar a Reprocesos", toast: "Estudio enviado a Reprocesos" },
  { id: 5, text: "Regresar a Captura", toast: "Estudio regresado a Captura" },
  {
    id: 6,
    text: "Regresar a Validación",
    toast: "Estudio regresado a Validación",
  },
  {
    id: 7,
    text: "Regresar a En Proceso",
    toast: "Estudio regresado a En Proceso",
  },
  {
    id: 8,
    text: "Regresar a Completado",
    toast: "Estudio regresado a Completado",
  },
  {
    id: 9,
    text: "Regresar a Reprocesos",
    toast: "Estudio regresado a Reprocesos",
  },
];

export const textosPPs = [
  { text: "Válida", value: "valid" },
  { text: "No válida", value: "invalid" },
  { text: "No se proporcionó", value: "notProvided" },
  { text: "Si", value: "s" },
  { text: "No", value: "n" },
  { text: "No disponible en SINDO", value: "na" },
  { text: "No proporcionado", value: "np" },
  { text: "No corresponde", value: "no_corresponde" },
  {
    text: "Corresponde sin homoclave",
    value: "corresponde_sin_homoclave",
  },
  {
    text: "No proporcionó RFC",
    value: "nprfc",
  },
  {
    text: "Corresponde con homoclave",
    value: "corresponde_con_homoclave",
  },
  {
    text: "No proporcionó estado de cuenta de su AFORE",
    value: "np",
  },
];

export const camposPPs = [
  {
    text: "Confirmación de respuesta del INE",
    value: "confirmacion_respuesta_ine",
    yup: Yup.mixed().oneOf(["valid", "invalid", "notProvided"]).required(),
    type: "dropdown",
    options: [
      { text: "Válida", value: "valid" },
      { text: "No válida", value: "invalid" },
      { text: "No se proporcionó", value: "notProvided" },
    ],
  },
  {
    text: "Confirmación de respuesta del RENAPO",
    value: "confirmacion_respuesta_renapo",
    yup: Yup.mixed().oneOf(["valid", "invalid", "notProvided"]).required(),
    type: "dropdown",
    options: [
      { text: "Válida", value: "valid" },
      { text: "No válida", value: "invalid" },
      { text: "No se proporcionó", value: "notProvided" },
    ],
  },
  {
    text: "Ubica su cuenta de ahorro para el retiro en la AFORE",
    value: "ubicacion_cuenta_afore",
    // yup: Yup.string().required("La ubicación de la cuenta AFORE es requerida"),
    // type: "input",
    // options: "",
    yup: Yup.mixed()
      .oneOf([
        "AFORE Azteca",
        "Citibanamex AFORE",
        "AFORE Coppel",
        "PENSIONISSSTE",
        "AFORE Inbursa",
        "AFORE Invercap",
        "Principal AFORE",
        "Profuturo AFORE",
        "AFORE SURA",
        "AFORE XXI Banorte",
      ])
      .required(),
    type: "dropdown",
    options: [
      { text: "AFORE Azteca", value: "AFORE Azteca" },
      { text: "Citibanamex AFORE", value: "Citibanamex AFORE" },
      { text: "AFORE Coppel", value: "AFORE Coppel" },
      { text: "PENSIONISSSTE", value: "PENSIONISSSTE" },
      { text: "AFORE Inbursa", value: "AFORE Inbursa" },
      { text: "AFORE Invercap", value: "AFORE Invercap" },
      { text: "Principal AFORE", value: "Principal AFORE" },
      { text: "Profuturo AFORE", value: "Profuturo AFORE" },
      { text: "AFORE SURA", value: "AFORE SURA" },
      { text: "AFORE XXI Banorte", value: "AFORE XXI Banorte" },
    ],
  },
  {
    text: "CURP proporcionada coincide con CURP de SINDO",
    value: "curp_coincide_sindo",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
      { text: "No disponible en SINDO", value: "na" },
      { text: "No proporcionado", value: "np" },
    ],
  },
  {
    text: "NSS coincide con el NSS del SINDO",
    value: "nss_coincide_sindo",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "NSS coincide con el estado de cuenta de su AFORE",
    value: "nss_coincide_afore",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
      {
        text: "No proporcionó estado de cuenta de su AFORE",
        value: "np",
      },
    ],
  },
  {
    text: "RFC coincide con el estado de cuenta de su AFORE",
    value: "rfc_coincide_afore",
    type: "dropdown",
    options: [
      { text: "No corresponde", value: "no_corresponde" },
      {
        text: "Corresponde sin homoclave",
        value: "corresponde_sin_homoclave",
      },
      {
        text: "Corresponde con homoclave",
        value: "corresponde_con_homoclave",
      },
      {
        text: "No proporcionó estado de cuenta de su AFORE",
        value: "np",
      },
      {
        text: "No proporcionó RFC",
        value: "nprfc",
      },
    ],
  },
  {
    text: "Número de semanas en reporte digital",
    value: "semanas_reporte_digital",
    type: "number",
    options: "",
  },
  {
    text: "Número de semanas en reporte SINDO",
    value: "semanas_reporte_sindo",
    type: "number",
    options: "",
  },
  {
    text: "Fecha de cotización más antigua en régimen obligatorio",
    value: "fecha_cotizacion_antigua",
    type: "date",
    options: "",
  },
  {
    text: "Patrón de cotización más antiguo en régimen obligatorio",
    value: "patron_cotizacion_antigua",
    type: "text",
    options: "",
  },
  {
    text: "Fecha de cotización más reciente en régimen obligatorio",
    value: "fecha_cotizacion_reciente",
    type: "date",
    options: "",
  },
  {
    text: "Patrón de cotización más reciente en régimen obligatorio",
    value: "patron_cotizacion_reciente",
    type: "text",
    options: "",
  },
  {
    text: "Cuenta con las semanas mínimas para tramitar pensión.",
    value: "semanas_minimas_requeridas_pension",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "Número de semanas descontadas por préstamo en desempleo",
    value: "semanas_descontadas_prestamo_desempleo",
    type: "number",
    options: "",
  },
  {
    text: "Tiene conservación de derechos en SINDO",
    value: "conserva_derechos_sindo",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "Cuenta con vigencia de derechos para incorporarse a Modalidad 40",
    value: "vigencia_derechos_modalidad_40",
    type: "dropdown",
    options: [
      { text: "Si", value: "s" },
      { text: "No", value: "n" },
    ],
  },
  {
    text: "Fecha límite de alta en modalidad 40",
    value: "fecha_limite_alta_modalidad_40",
    type: "date",
    options: "",
  },
  {
    text: "Salario base de cotización",
    value: "salario_base_cotizacion",
    type: "number",
    options: "",
    step: "0.10",
  },

  {
    text: "Salario promedio últimas 260 semanas",
    value: "salario_promedio_ultimas_260_semanas",
    type: "number",
    options: "",
  },
  {
    text: "Porcentaje del salario tope",
    value: "porcentaje_salario_tope",
    type: "number",
    options: "",
  },
];

export const afores = [
  { text: "AFORE Azteca", value: "AFORE Azteca" },
  { text: "Citibanamex AFORE", value: "Citibanamex AFORE" },
  { text: "AFORE Coppel", value: "AFORE Coppel" },
  { text: "PENSIONISSSTE", value: "PENSIONISSSTE" },
  { text: "AFORE Inbursa", value: "AFORE Inbursa" },
  { text: "AFORE Invercap", value: "AFORE Invercap" },
  { text: "Principal AFORE", value: "Principal AFORE" },
  { text: "Profuturo AFORE", value: "Profuturo AFORE" },
  { text: "AFORE SURA", value: "AFORE SURA" },
  { text: "AFORE XXI Banorte", value: "AFORE XXI Banorte" },
];

export const tableB = [
  { f0: "1.00", basic_amount: ".8000", annual_increase: "0.56" },
  { f0: "1.01", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.02", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.03", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.04", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.05", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.06", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.07", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.08", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.09", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.10", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.11", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.12", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.13", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.14", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.15", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.16", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.17", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.18", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.19", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.20", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.21", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.22", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.23", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.24", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.25", basic_amount: ".7711", annual_increase: "0.81" },
  { f0: "1.26", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.27", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.28", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.29", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.30", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.31", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.32", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.33", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.34", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.35", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.36", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.37", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.38", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.39", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.40", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.41", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.42", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.43", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.44", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.45", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.46", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.47", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.48", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.49", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.50", basic_amount: ".5818", annual_increase: "1.18" },
  { f0: "1.51", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.52", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.53", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.54", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.55", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.56", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.57", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.58", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.59", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.60", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.61", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.62", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.63", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.64", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.65", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.66", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.67", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.68", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.69", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.70", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.71", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.72", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.73", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.74", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.75", basic_amount: ".4923", annual_increase: "1.43" },
  { f0: "1.76", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.77", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.78", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.79", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.80", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.81", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.82", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.83", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.84", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.85", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.86", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.87", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.88", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.89", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.90", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.91", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.92", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.93", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.94", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.95", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.96", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.97", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.98", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "1.99", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "2.00", basic_amount: ".4267", annual_increase: "1.62" },
  { f0: "2.01", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.02", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.03", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.04", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.05", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.06", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.07", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.08", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.09", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.10", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.11", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.12", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.13", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.14", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.15", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.16", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.17", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.18", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.19", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.20", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.21", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.22", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.23", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.24", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.25", basic_amount: ".3765", annual_increase: "1.76" },
  { f0: "2.26", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.27", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.28", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.29", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.30", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.31", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.32", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.33", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.34", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.35", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.36", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.37", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.38", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.39", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.40", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.41", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.42", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.43", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.44", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.45", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.46", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.47", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.48", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.49", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.50", basic_amount: ".3368", annual_increase: "1.87" },
  { f0: "2.51", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.52", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.53", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.54", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.55", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.56", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.57", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.58", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.59", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.60", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.61", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.62", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.63", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.64", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.65", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.66", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.67", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.68", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.69", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.70", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.71", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.72", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.73", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.74", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.75", basic_amount: ".3048", annual_increase: "1.96" },
  { f0: "2.76", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.77", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.78", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.79", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.80", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.81", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.82", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.83", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.84", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.85", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.86", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.87", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.88", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.89", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.90", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.91", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.92", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.93", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.94", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.95", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.96", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.97", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.98", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "2.99", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "3.00", basic_amount: ".2783", annual_increase: "2.03" },
  { f0: "3.01", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.02", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.03", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.04", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.05", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.06", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.07", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.08", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.09", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.10", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.11", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.12", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.13", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.14", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.15", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.16", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.17", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.18", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.19", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.20", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.21", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.22", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.23", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.24", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.25", basic_amount: ".2560", annual_increase: "2.10" },
  { f0: "3.26", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.27", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.28", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.29", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.30", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.31", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.32", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.33", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.34", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.35", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.36", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.37", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.38", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.39", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.40", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.41", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.42", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.43", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.44", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.45", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.46", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.47", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.48", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.49", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.50", basic_amount: ".2370", annual_increase: "2.15" },
  { f0: "3.51", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.52", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.53", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.54", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.55", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.56", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.57", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.58", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.59", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.60", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.61", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.62", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.63", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.64", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.65", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.66", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.67", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.68", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.69", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.70", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.71", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.72", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.73", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.74", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.75", basic_amount: ".2207", annual_increase: "2.20" },
  { f0: "3.76", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.77", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.78", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.79", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.80", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.81", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.82", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.83", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.84", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.85", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.86", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.87", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.88", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.89", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.90", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.91", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.92", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.93", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.94", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.95", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.96", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.97", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.98", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "3.99", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "4.00", basic_amount: ".2065", annual_increase: "2.24" },
  { f0: "4.01", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.02", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.03", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.04", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.05", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.06", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.07", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.08", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.09", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.10", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.11", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.12", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.13", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.14", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.15", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.16", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.17", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.18", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.19", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.20", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.21", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.22", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.23", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.24", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.25", basic_amount: ".1939", annual_increase: "2.27" },
  { f0: "4.26", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.27", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.28", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.29", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.30", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.31", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.32", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.33", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.34", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.35", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.36", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.37", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.38", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.39", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.40", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.41", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.42", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.43", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.44", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.45", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.46", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.47", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.48", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.49", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.50", basic_amount: ".1829", annual_increase: "2.30" },
  { f0: "4.51", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.52", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.53", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.54", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.55", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.56", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.57", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.58", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.59", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.60", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.61", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.62", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.63", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.64", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.65", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.66", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.67", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.68", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.69", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.70", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.71", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.72", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.73", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.74", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.75", basic_amount: ".1730", annual_increase: "2.33" },
  { f0: "4.76", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.77", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.78", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.79", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.80", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.81", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.82", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.83", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.84", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.85", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.86", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.87", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.88", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.89", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.90", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.91", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.92", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.93", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.94", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.95", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.96", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.97", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.98", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "4.99", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "5.00", basic_amount: ".1641", annual_increase: "2.36" },
  { f0: "5.01", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.02", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.03", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.04", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.05", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.06", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.07", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.08", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.09", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.10", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.11", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.12", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.13", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.14", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.15", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.16", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.17", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.18", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.19", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.20", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.21", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.22", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.23", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.24", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.25", basic_amount: ".1561", annual_increase: "2.38" },
  { f0: "5.26", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.27", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.28", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.29", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.30", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.31", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.32", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.33", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.34", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.35", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.36", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.37", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.38", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.39", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.40", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.41", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.42", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.43", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.44", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.45", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.46", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.47", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.48", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.49", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.50", basic_amount: ".1488", annual_increase: "2.40" },
  { f0: "5.51", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.52", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.53", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.54", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.55", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.56", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.57", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.58", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.59", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.60", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.61", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.62", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.63", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.64", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.65", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.66", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.67", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.68", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.69", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.70", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.71", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.72", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.73", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.74", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.75", basic_amount: ".1422", annual_increase: "2.42" },
  { f0: "5.76", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.77", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.78", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.79", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.80", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.81", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.82", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.83", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.84", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.85", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.86", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.87", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.88", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.89", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.90", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.91", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.92", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.93", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.94", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.95", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.96", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.97", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.98", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "5.99", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "6.00", basic_amount: ".1362", annual_increase: "2.43" },
  { f0: "6.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "6.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "7.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "8.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "9.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "10.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "11.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "12.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "13.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.00", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.01", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.02", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.03", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.04", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.05", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.06", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.07", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.08", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.09", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.10", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.11", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.12", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.13", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.14", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.15", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.16", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.17", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.18", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.19", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.20", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.21", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.22", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.23", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.24", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.25", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.26", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.27", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.28", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.29", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.30", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.31", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.32", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.33", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.34", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.35", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.36", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.37", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.38", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.39", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.40", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.41", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.42", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.43", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.44", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.45", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.46", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.47", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.48", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.49", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.50", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.51", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.52", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.53", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.54", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.55", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.56", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.57", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.58", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.59", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.60", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.61", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.62", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.63", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.64", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.65", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.66", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.67", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.68", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.69", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.70", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.71", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.72", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.73", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.74", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.75", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.76", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.77", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.78", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.79", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.80", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.81", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.82", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.83", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.84", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.85", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.86", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.87", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.88", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.89", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.90", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.91", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.92", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.93", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.94", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.95", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.96", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.97", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.98", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "14.99", basic_amount: ".1300", annual_increase: "2.45" },
  { f0: "15.00", basic_amount: ".1300", annual_increase: "2.45" },
];

export const tableC = [
  { age: 60, percent: 75.0 },
  { age: 61, percent: 80.0 },
  { age: 62, percent: 85.0 },
  { age: 63, percent: 90.0 },
  { age: 64, percent: 95.0 },
  { age: 65, percent: 100.0 },
];

export const minSdi = 207;
export const maxSdi = 2590;
export const minAge = 60;
export const maxAge = 65;
export const minQuotedWeeks = 500;
export const maxQuotedWeeks = 2350;
export const max_daily_pension = 2590;
export const min_montly_pension = 7005;
export const max_montly_pension = 78842.5;
export const days_by_month = 30.4166666666667;
export const factor_constant = 62.33;
export const wife_factor = 0.15;
export const son_factor = 0.1;
