import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";
import { selectCurrentUser } from "../../../store/slices/authSlice";

import "./Completados.scss";

const Completados = () => {
  const user = useSelector(selectCurrentUser);

  return (
    <Container>
      <div className="header">Estudios - Completados</div>
      <div className="captura">
        <ListaEstudios status="4" user_id={user.id} />
      </div>
    </Container>
  );
};

export default Completados;
