import { useState } from "react";
import ListaFacturacion from "./ListaFacturacion";
import DetallesFacturacion from "./DetallesFacturacion";
import EditarFacturacion from "./EditarFacturacion";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/slices/authSlice";

const Facturacion = () => {
  const user = useSelector(selectCurrentUser);
  const [detalleId, setDetalleId] = useState("");
  const [edit, setEdit] = useState(false);

  return (
    <div className="container">
      <div className="perfil-table-container">
        <div className="subheader">Facturación</div>
        {edit ? (
          <EditarFacturacion
            user_id={user.id}
            detalleId={detalleId}
            setEdit={setEdit}
          />
        ) : detalleId ? (
          <DetallesFacturacion
            detalleId={detalleId}
            setDetalleId={setDetalleId}
            setEdit={setEdit}
          />
        ) : (
          <ListaFacturacion
            user_id={user.id}
            setDetalleId={setDetalleId}
            setEdit={setEdit}
          />
        )}
      </div>
    </div>
  );
};

export default Facturacion;
