import { useNavigate, useParams } from "react-router-dom";
import usePublicPp from "./usePublicPp";
import { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { NumericFormat } from "react-number-format";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useGetDetailsQuery } from "../../../../services/endpoints/pps";
import { urlBaseAdministracion } from "../../../../constants/constants";

import "./PublicPP.scss";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);

const PublicPP = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: pp_data = [] } = useGetDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const {
    sdi,
    setSdi,
    age,
    setAge,
    quotedWeeks,
    setQuotedWeeks,
    MIN_SDI,
    MAX_SDI,
    MIN_AGE,
    MAX_AGE,
    MIN_QUOTED_WEEKS,
    MAX_QUOTED_WEEKS,
    MAX_MONTHLY_PENSION,
    monthlyPension,
  } = usePublicPp();

  useEffect(() => {
    if (pp_data.length > 0) {
      const newSdi = parseFloat(pp_data[0].semanas_reporte_sindo);
      const newQuotedWeeks = parseFloat(
        pp_data[0].salario_promedio_ultimas_260_semanas
      );

      setSdi(newSdi);
      setQuotedWeeks(newQuotedWeeks);
    }
  }, [pp_data, setSdi, setAge, setQuotedWeeks]);

  const data = {
    labels: ["Pensión actual", "Máxima pensión posible"],
    datasets: [
      {
        label: "Variable",
        data: [monthlyPension, MAX_MONTHLY_PENSION],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(63, 191, 63, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(63, 191, 63, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
  };

  const handleBack = () => {
    navigate(`${urlBaseAdministracion}/detalles?id=${id}`);
  };

  return (
    <Container className="public-pp">
      <div className="slider-container">
        Salario diario SDI:{" "}
        <NumericFormat
          value={sdi}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$ "}
        />
        <input
          type="range"
          min={MIN_SDI}
          max={MAX_SDI}
          className="slider"
          disabled
          onChange={(e) => {
            setSdi(Math.ceil(e.target.value));
          }}
          defaultValue={sdi}
        />
        Edad:{" "}
        <NumericFormat
          value={age}
          displayType={"text"}
          thousandSeparator={true}
        />
        <input
          type="range"
          min={MIN_AGE}
          max={MAX_AGE}
          className="slider"
          onChange={(e) => {
            setAge(e.target.value);
          }}
          defaultValue={age}
        />
        Semanas cotizadas:{" "}
        <NumericFormat
          value={quotedWeeks}
          displayType={"text"}
          thousandSeparator={true}
        />
        <input
          type="range"
          min={MIN_QUOTED_WEEKS}
          max={MAX_QUOTED_WEEKS}
          className="slider"
          disabled
          onChange={(e) => {
            setQuotedWeeks(e.target.value);
          }}
          defaultValue={quotedWeeks}
        />
      </div>

      {/* Gráfico de Barras */}
      <div className="bar-chart-container">
        <Bar data={data} options={options} width={100} height={50}></Bar>
      </div>

      {/* Botón para regresar */}
      <div id="botonera1">
        <button onClick={handleBack} className="ui button">
          Regresar
        </button>
      </div>
    </Container>
  );
};

export default PublicPP;
