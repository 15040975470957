import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";
import { selectCurrentUser } from "../../../store/slices/authSlice";

import "./Reprocesos.scss";

const Reprocesos = () => {
	const user = useSelector(selectCurrentUser);

	return (
		<Container>
			<div className="header">Estudios - Reprocesos</div>
			<div className="captura">
				<ListaEstudios status="5" user_id={user.id} />
			</div>
		</Container>
	);
};

export default Reprocesos;
