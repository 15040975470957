import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  asesor: "",
  status: "",
  registro_facturacion: "",
  list: [],
  details: null,
};

export const studiesSlice = createSlice({
  name: "studies",
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setAsesor: (state, action) => {
      state.asesor = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setRegistroFacturacion: (state, action) => {
      state.registro_facturacion = action.payload;
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    setDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});

// Action creators
export const {
  setId,
  setAsesor,
  setStatus,
  setRegistroFacturacion,
  setList,
  setDetails,
} = studiesSlice.actions;

export default studiesSlice.reducer;
