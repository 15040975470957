import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Container, Form } from "semantic-ui-react";
import {
  regimenesFiscales,
  tiposPersonas,
  usosCfdi,
} from "../../../constants/constants";
import {
  useCreateFacturaMutation,
  useGetFacturaQuery,
  useUpdateFacturaMutation,
} from "../../../services/endpoints/facturacion";
import { setMessage } from "../../../store/slices/statusSlice";
import { selectCurrentUser } from "../../../store/slices/authSlice";

const EditarFacturacion = ({ detalleId, setEdit }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const { data: fact = {} } = useGetFacturaQuery(detalleId, {
    refetchOnMountOrArgChange: true,
  });

  const [updateFactura, { isSuccess: isSuccessUpdate }] =
    useUpdateFacturaMutation();
  const [createFactura, { isSuccess: isSuccessCreate }] =
    useCreateFacturaMutation();

  const isSuccess = isSuccessUpdate || isSuccessCreate;

  const formik = useFormik({
    initialValues: {
      tipo_persona: fact.tipo_persona || "Persona Física",
      rfc: fact.rfc || "",
      razon_social: fact.razon_social || "",
      codigo_postal: fact.codigo_postal || "",
      regimen_fiscal:
        fact.regimen_fiscal || "601 - General de Ley Personas Morales",
      email: fact.email || "",
      uso_cfdi: fact.uso_cfdi || "G03 - Gastos en general",
    },
    validationSchema: Yup.object({
      tipo_persona: Yup.string().required("Tipo de persona es requerido"),
      rfc: Yup.string().required("RFC es requerido"),
      razon_social: Yup.string().required("Razón social es requerido"),
      codigo_postal: Yup.string().required("Código postal es requerido"),
      regimen_fiscal: Yup.string().required("Régimen fiscal es requerido"),
      email: Yup.string().email().required("Correo electrónico es requerido"),
      uso_cfdi: Yup.string().required("Uso de CFDI es requerido"),
    }),
    onSubmit: (formData) => {
      if (detalleId) {
        formData.detalleId = detalleId;
        formData.user_id = user.id;
        updateFactura(formData);
      } else {
        formData.user_id = user.id;
        createFactura(formData);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage("Operación exitosa"));
      setEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Container>
      <div className="subsubheader">{detalleId ? "Editar" : "Nuevo"}</div>
      <Form onSubmit={formik.handleSubmit} className="register">
        <label htmlFor="tipo_persona" className="labels">
          Tipo de persona
        </label>
        <select
          id="tipo_persona"
          name="tipo_persona"
          value={formik.values.tipo_persona}
          onChange={formik.handleChange}
          className="perfil-select"
        >
          {tiposPersonas.map((item) => {
            return (
              <option key={item.text} value={item.text}>
                {item.text}
              </option>
            );
          })}
        </select>
        <Form.Input
          type="text"
          placeholder="ABCD123456XYZ"
          name="rfc"
          label="RFC"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.rfc}
          error={formik.touched.rfc && formik.errors.rfc}
          className="register__input"
        />
        <Form.Input
          type="text"
          placeholder="Nombre de la empresa"
          name="razon_social"
          label="Razón social"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.razon_social}
          error={formik.touched.razon_social && formik.errors.razon_social}
          className="register__input"
        />
        <Form.Input
          type="text"
          placeholder="Código postal"
          name="codigo_postal"
          label="Código postal"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.codigo_postal}
          error={formik.touched.codigo_postal && formik.errors.codigo_postal}
          className="register__input"
        />
        <label htmlFor="tipo_persona" className="labels">
          Régimen fiscal
        </label>
        <select
          id="regimen_fiscal"
          name="regimen_fiscal"
          value={formik.values.regimen_fiscal}
          onChange={formik.handleChange}
          className="perfil-select"
        >
          {regimenesFiscales.map((item) => {
            return (
              <option key={item.text} value={item.text}>
                {item.text}
              </option>
            );
          })}
        </select>
        <Form.Input
          type="email"
          placeholder="Correo electrónico"
          name="email"
          label="Correo electrónico"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
          className="register__input"
        />
        <label htmlFor="uso_cfdi" className="labels">
          Uso de CFDI
        </label>
        <select
          id="uso_cfdi"
          name="uso_cfdi"
          value={formik.values.uso_cfdi}
          onChange={formik.handleChange}
          className="perfil-select"
        >
          {usosCfdi.map((item) => {
            return (
              <option key={item.text} value={item.text}>
                {item.text}
              </option>
            );
          })}
        </select>

        <div id="botonera">
          <Button
            type="button"
            className="secondary-button"
            onClick={() => setEdit(false)}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            className="primary-button"
            onClick={formik.handleSubmit}
          >
            Guardar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditarFacturacion;
